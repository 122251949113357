@import url(Normalize.css);
@import url(./css/animations.css);
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


:root {
  --dark: #212122;
  --sm-text-color: #445b78;
  --transition: all 0.35s ease;
  --brand50: #FFF9F9;
  --brand100: #FDDDDC;
  --brand200: #FBC3C1;
  --brand300: #F9AAA6;
  --brand: #f25c54;
  --brand500: #E65A53;
  --brand600: #D95851;
  --error50: #FEF3F2;
  --error: #F04438;
  --error700: #B42318;
  --warning50: #FFFAEB;
  --warning400: #FDB022;
  --warning: #F79009;
  --warning600: #DC6803;
  --warning700: #B54708;
  --orange: #F38744;
  --orange50: #FEF6EE;
  --orange700: #B93815;
  --success: #32D583;
  --success50: #ECFDF3;
  --success700: #027A48;
  --grey25: #FCFCFD;
  --grey50: #f9fafa;
  --grey100: #f4f7fe;
  --grey150: #EBEEF1;
  --borders: #EBEEF1;
  --grey300: #A6B1C2;
  --grey400: #98A2B3;
  --grey500: #667085;
  --grey600: #373C48;
  --grey700: #292D36;
  --blue50: #ECF3FF;
  --blue400: #528BFF;
  --blue600: #155EEF;
  --teal50: #F0FDF9;
  --teal100: #CCFBEF;
  --teal500: #15B79E;
  --fuchsia50: #FDF4FF;
  --fuchsia100: #FBE8FF;
  --fuchsia500: #D444F1;
  --purple50: #F9F5FF;
  --purple600: #7F56D9;
  --yellow50: #FEFBE8;
  --yellow600: #CA8504;
  --padding-xl: 96px;
  --padding-lg: calc(var(--padding-xl) / 2);
  --padding-md: calc(var(--padding-xl) / 3);
  --padding-sm: calc(var(--padding-xl) / 4);
  --padding-xs: calc(var(--padding-xl) / 6);
  --padding-xxs: calc(var(--padding-xl) / 12);
  --radius-xl: calc(var(--radius-xs) * 5);
  --radius-lg: calc(var(--radius-xs) * 4);
  --radius-md: calc(var(--radius-xs) * 3);
  --radius-sm: calc(var(--radius-xs) * 2);
  --radius-xs: 4px;
  --transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-slow: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --box-shadow: 0 7px 12px 0 rgba(0,0,0,.2);
  --switch-bg: var(--brand);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: var(--success) !important;
  padding: var(--padding-xs);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: var(--error) !important;
  padding: var(--padding-xs);
}
.Toastify__close-button {
  align-self: center !important;
}

* {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  position: relative;
}
#root {
  max-height: 100dvh;
  display: flex;
}
body,
html {
  margin: 0;
  padding: 0;
  color: var(--grey700);
  height: 100dvh;
  width: 100%;
  font-size: 15px;
  font-family: 'Inter', sans-serif !important;
}
html {
  height: 100%;
}
@media (max-width:767px) {
  body {
    font-size: 14px;
  }
  .mobile-hidden {
    display: none !important;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--grey700);
  line-height: 1.15;
  margin: 0;
  font-weight: 600;
}
p {
  color: var(--sm-text-color);
}

.backend a,
.backend a:hover,
.backend .text-link:hover {
  color: var(--brand500);
}
h1 {
  font-size: 2.25em;
  text-transform: capitalize;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.75em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.25em;
}
h6 {
  font-size: 1.07em;
}
a,
button, 
label {
  cursor: pointer !important;
}
.toolTip {
  font-size: 15px !important;
  background-color: white;
  color: #2e3333;
  max-width: 280px !important; 
  padding : 16px; 
  z-index: 99;
  font-weight: 500;
}
header,
footer,
section,
nav {
  display: flex;
}

form {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row !important;
  gap: 8px;
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}

.field {
  flex-direction: column;
  display: flex;
  gap: 4px;
  width: 100%;
  padding: 4px 0;
}
.field.checkbox {
  width: fit-content;
  max-width: 100px;
}
.field.checkbox input[type=checkbox] {
  height: 20px;
  width: 20px;
}
.field.menuIcon {
  width: 60px;
}

label {
  font-weight: 500;
  font-size: 12px;
}

input[type=text]:not(.customSelect__input),
input[type=number],
input[type=phone],
input[type=email],
input[type=password],
input[type=url],
select:not(.yearSelector),
textarea, 
.restaurantDropdownInput {
  font-size: 15px;
  color: var(--sm-text-color);
  display: flex;
  flex: 1 1;
  outline: none;
  background-color: white;
  padding: 10px 14px;
  border: none;
  box-shadow: 0 0 0 1.5px var(--borders);
  border-radius: 8px;
}
input.datePicker {
  padding-left: 42px !important;
  max-width: 100%;
}

input[type=text]:not(.customSelect__input),
input[type=number]:not(.markup),
input[type=phone],
input[type=email],
input[type=password],
input[type=url],
select:not(.yearSelector),
textarea, 
.restaurantDropdownInput,
.chakra-input__left-addon,
.chakra-input__right-addon {
  height: 42px !important;
}
input[type=text]:not(.customSelect__input):focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=url]:focus,
select:focus,
textarea:focus,
input:not(.customSelect__input):focus-visible {
  box-shadow: 0 0 0 1.5px var(--brand);
  border-color: transparent;
}


input[type=checkbox] {
  height: 20px;
  width: 20px;
  appearance: auto;
}
input[type=text].nameInput {
  background-color: transparent;
  font-weight: 600;
  font-size: 1.2em;
  color: var(--grey700);
  min-height: 0;
}
.itemForm input[type=text].nameInput {
  padding: 0;
  box-shadow: none;
  border-radius: 0;

}
input[type=text].nameInput:focus {
  box-shadow: none !important;
}


textarea.descInput {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  font-size: 15px;
  color: var(--sm-text-color);
  flex: auto;
  height: auto;
  resize: none;
  min-height: 45px;
}
select {
  text-transform: capitalize;
    appearance: auto;
    height: 42px !important;
    padding-right: 32px !important;
}
select:focus-visible {
  box-shadow: 0 0 0 1.5px var(--brand) !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  margin-inline-start: 0 !important;
}

@media (min-width: 960px) {
  .overlay {
    display: none !important;
  }
}



img {
  max-width: 100%;
}
button,
.btn {
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  transition: var(--transition);
  height: fit-content;
  flex-direction: row !important;
  gap: var(--padding-xxs);
  cursor: pointer;
}
.EmojiPickerReact button,
button.rdrStaticRange {
  padding: 0 !important;
  color: var(--grey700);
}

.primary,
.btn.primary {
  background-color: var(--brand);
  color: white;
}
.primary:hover,
.btn.primary:hover {
  background-color: var(--brand500);
}
.btn.primary:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.secondary,
.btn.secondary {
  background-color: var(--grey100) !important;
  color: black !important;
}
.secondary:hover,
.btn.secondary:hover {
  background-color: var(--grey150) !important;
}
.btn.icon-btn {
  padding: 0 !important;
  height: 48px;
  width: 48px;
  border-radius: 100px;
}

.btn.icon-btn path {
  fill: white;
}
.btn.icon-btn.secondary path {
  fill: black;
}
.btn.sm {
  padding: 4px 16px !important;
  font-size: 13px;
}
.btn.icon-btn.sm {
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0 !important;
}
.btn.icon-btn.sm svg {
  height: 16px;
  width: 16px;
}
button.simple,
.btn.simple {
  padding: 0 !important;
  background: none;
  color: var(--brand);
}
.text-link {
  color: var(--brand);
  font-weight: 500;
  cursor: pointer;
}

.tag {
  font-weight: 500;
  font-size: 13px;
  padding-right: var(--chakra-space-2);
  padding-left: var(--chakra-space-2);
  padding-top: var(--chakra-space-1);
  padding-bottom: var(--chakra-space-1);
  border-radius: 8px;
  width: fit-content;
  background-color: var(--grey100);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  color: var(--grey700);
}
.tag:hover {
  background-blend-mode: multiply;
}

  
.error {
  background-color: var(--error50);
  color: var(--error) !important;
}
.success {
  color: var(--success);
  background-color: var(--success50) !important;
}

.error:hover {
  background-color: var(--error700);
}
.success:hover {
  background-color: var(--success700) !important;
}




/* Header */

header.header {
  flex-direction: row !important;
  gap: var(--padding-sm);
  align-items: center;
  width: 100%;
}

header .left {
  width: fit-content;
}
header .right {
  justify-content: flex-end;
  flex-direction: row !important;
  flex: 1;
  gap: var(--padding-xs);
}



/* Login */

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
.login svg path {
  fill: var(--brand);
}
.login h1 {
  margin-bottom: var(--padding-xs);
  text-align: left;
}
.login .columns {
  height: 100%;
}
.login .field {
  border: none;
  padding: 0;
}
.login input {
  background-color: white;
  padding: 16px;
  color: var(--grey700);
  font-weight: 500;
  font-size: 16px;
}
.login button {
  padding: 16px;
}

.login .columns .login-wrapper {
  flex: 40%;
  display: flex;
  justify-content: center;
  padding: var(--padding-xl);
  flex-direction: row !important;
}
.login .columns .login-wrapper .login-container {
  width: 100%;
  max-width: 330px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.login-inside {
  display: flex;
  flex-direction: column;
  gap: var(--padding-lg);
}
form.login-form {
  gap: var(--padding-xs);
}
.bottom-text {
  font-size: 13px;
}

@media (max-width:1320px) {
  .login .columns .bg,
  .login .columns .login-wrapper {
    flex: 1;
  }
}
@media (max-width:960px) {
  .login .columns .login-wrapper {
    width: 100%;
    padding: var(--padding-xl) var(--padding-xs) !important;
  }
}



.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  align-items: center;
}

/* Navigation */
.topBar {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}
.topBar .container {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.profileButton {
  line-height: 1;
}




@media (max-width:960px) {
  .profileButton {
    width: 20%;
    justify-content:center;
    display:flex;
  }
}
.navLinks a {
  text-decoration: none;
  font-weight: 500;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  padding: var(--chakra-space-2) var(--chakra-space-4);
  min-height:46.5px;
}
.navLinks a,
.navLinks a .lucide {
  color: var(--grey300);
}
.navLinks a .status {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: var(--brand);
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 1;
}
.notification {
  background-color: var(--brand);
  color: white;
  font-size: 13px;
  font-weight: 500;
  height: 20px;
  min-width: 20px;
  padding: 0 4px;
  border-radius: 4px;
  align-items: center;
}

.navLinks a:hover {
  background-color: rgba(255, 255, 255, 0.02);
  color: white;
}
.navLinks a:hover,
.navLinks a:hover .lucide {
  color: white;
}

.navLinks a.active { 
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--brand);
}

.navLinks a.active {
  pointer-events: none;
}

.navLinks a.active .lucide {
  color: var(--brand);
}

.leftNav,
.rightNav {
  flex-direction: row !important;
  gap: 6px;
  align-items: center;
}
.viewMenu {
  font-size: 13px;
  font-weight: 500;
}



.mobileNavLink,
.mobile-nav > div {
  background-color:transparent !important; 
  color: var(--grey300) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: var(--chakra-space-4) 0 var(--chakra-space-8);
  gap: var(--chakra-space-2);
  width: 20%;
  height: auto !important;
  line-height: 1 !important;
  border-top: 2px solid transparent;
}
.mobileNavLink svg {
  min-width: 24px;
  min-height: 24px;
  display: block;
}

.mobileNavLink::before {
  display: none;
}

.mobileNavLink.active {
  color: var(--brand) !important;
  border-color: var(--brand);
}
.mobileNavLink.active svg * {
  stroke: var(--brand)
}

.navLinks a.disabled,
.mobileNavLink.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.submenuLink.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.submenuLink.disabled span {
  opacity: 0.5;
}


.widget {
  gap: var(--padding-xs);
  background: white;
  padding: 0 var(--padding-sm);
  border-left: 1px solid var(--borders);
  flex: 1 1 auto;
}
.widget .widgetTitle {
  flex-direction: row !important;
  min-height: 27.5px;
  gap: 8px;
}
.widget .widgetTitle h5 {
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.widgetBody {
  gap: var(--padding-sm);
  

}
.lucide {
  color: var(--grey400);
  stroke-width: 1.5px;
}
.lucide.sm {
  stroke-width: 1px;
}
.topIcons a:hover .lucide,
.lucide:hover {
  color: var(--grey600);
}
.topIcons a:hover > div {
  border-radius: 8px;
  background-color: var(--grey150);
}




.lucide * {
  vector-effect: non-scaling-stroke;
}

.column-header {
  flex-direction: row !important;
  justify-content: space-between;
}

.subCategoriesChaser {
  scrollbar-width: none;
}
.subCategoriesChaser::-webkit-scrollbar {
  display: none;
}
.subCategoriesChaserItem {
  white-space: nowrap;
}
.scrollMenuItem:hover {
  background-color: var(--grey100);
}
.scrollMenuItem.isSelected {
  background-color: var(--brand);
  color: white;
}


.order-categories .category-item {  
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: var(--radius-sm);
}
.order-categories .category-item:last-of-type {
  border-bottom: 0;
}
.order-categories .category-item span {
  font-weight: 400;
  color: var(--sm-text-color);
  font-size: .9em;
}


.category-item input {
  padding: 0;
  box-shadow: none !important;
  min-width: 0;
  height: 18.4px;
}

@media (min-width: 960px) {
  .categoriesScroll {
    max-height: 40vh;
    overflow-y: auto;
    scrollbar-width: none;
  }
  }


.list {
  gap: var(--padding-xxs);
}


.icons {
  flex-direction: row !important;
  align-items: center;
}
.icon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon svg {
  width: 16px;
}

.empty-message {
  display: flex;
  flex-direction: column;
  gap: var(--padding-xs);
}
.empty-message button {
  width: fit-content;
}

.menuListHeader {
  width: 100%;  
  padding: var(--padding-sm) 0;
}
.menuListHeader > .container {
  justify-content: space-between;
  flex-direction: row !important;
}
.menuListTitle {
  flex-direction: row !important;
  gap: var(--padding-xs);
  align-items: center;
}
.menuListTitle h1 {
  white-space: nowrap;
}

.category-title {
  flex-direction: row !important;
  gap: var(--padding-xxs);
  align-items: center;
}
.category-title h5 {
  text-transform: capitalize;
}

.dish {
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-xxs) 0;
  cursor: pointer;
  background-color: white;
  border-radius: var(--radius-sm);
}
.dish::after,
.user::after,
.category-item::after {
  content: '';
  position: absolute;
  background-color: #F2F2F2;
  height: 1px;
  width: calc(100% - 32px);
  left: 16px;
  right: 16px ;
  bottom: 0;
}
.dishes-grid .dish:last-of-type::after,
.user:last-of-type:after,
.category-item:last-of-type:after {
display: none;
}
.dish:hover{
  background-color: var(--grey25);
}
.dish:active {
  cursor: grabbing;
}
.dish-name,
.user-name {
  display: flex;
  flex-direction: row !important;
  gap: var(--padding-xxs);
  align-items: center;
  flex: 1;
  padding: 0 var(--padding-xs);
  padding-left: 0;
  font-weight: 500;
  min-width: 0;
  max-width: 360px;
  font-size: 15px;
}
.dish-name svg {
  min-width: 16px;
}

.dish-img,
.itemImage {
  width: 32px;
  min-width: 32px;
  height: 32px;
  position: relative;
  border-radius: var(--radius-sm);
  overflow: hidden;
}
.dish-img img,
.itemImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}
.dish:hover .dish-img img {
  transform: scale(1.1);
}
.itemImage.cartImage {
  height: 52px;
  width: 52px;
  border-radius: 26px;
  background-color: var(--grey100);
}

.dish-info,
.user-info {
  flex-direction: row !important;
}
.dish-info > div.dish-display {
  flex-direction: row;
  gap: 4px;
}

.dish-info > div:last-of-type,
.user-info > div:last-of-type,
.dish-info > .dish-price {
  padding-right: 0;
}
.dish-price {
  flex-direction: row !important;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey700);
  font-weight: 500;
  font-size: 15px;
}
.user-email {
  min-width: 220px;
}
.user-restaurant {
  min-width: 100px;
  text-transform: capitalize;
}




.info p {
  max-width: 85%;
}

@media (max-width: 960px) {
 
  .menuListHeader {
    flex-direction: column;
    justify-content: center;
    gap: var(--padding-sm);
  }
  .widget,
  .users-grid,
  .order-categories {
  border-radius: 0;
  max-width: calc(100vw + 2px);
  border-left: 0;
  border-right: 0;
}

.menuItems .widget {
  border-top:0
}
}

#edit-item-modal {
  display: flex;
  position: fixed;
  z-index: 1001;
  right: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
}

#edit-item-modal,
.modal {
  padding: 1vh;
  display: flex;
  justify-content: center;
  outline: none;
  max-height: 100%;
  height: fit-content;
}
@media (max-width: 767px) {
  .modal {
    width: 100%;
  }
}
.categoryMenu {
  scrollbar-width: 0;
}
.categoryMenu::-webkit-scrollbar {
  display: none;
}

.modal-content {
  background-color: var(--grey100);
  color: var(--grey700);
  flex-direction: column;
  width: 100%;
  position: relative;
  width: 460px;
  max-width: 100%;
  overflow-y: scroll;
  scrollbar-width: 0;
  border-radius: var(--radius-md);
  outline: none;
}
.modal-content::-webkit-scrollbar {
  display: none;
}


.modalInner {
  padding: var(--padding-sm);
  display: flex;
  flex-direction: column;
  gap: var(--padding-xs);
  background-color: white;
}

.import-modal .close {
  position: absolute;
  right: 24px;
}
.modal-content .img-plat {
  max-width: initial;
  width: 100%;
  padding-top: 400px;
  height: 0;
  border-radius: 0;
}

.modal-content .fieldset {
  padding: var(--padding-sm);
  flex-direction: column;
  gap: var(--padding-xxs);
  flex: 1;
  background-color: white;
  display: flex;
  border-bottom: 1px solid var(--borders);
}



.modal-content.edit form input[name="allergènes"] {
  text-transform: capitalize;
}
.buttons {
  display: flex;
  flex-direction: row !important;
  gap: 4px !important;
}
.modalTopBar {
  position: sticky;
  top: 0;
  padding: var(--padding-xs);
  background-color: #FFF;
  z-index: 11;
  border-bottom: 1px solid var(--borders);
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}
.modalTitle {
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
}

.close {
  display: flex;
  font-size: 24px;
  font-weight: 400;
  color: var(--grey100);
  height: 32px;
  width: 32px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background-color: white;
  line-height: 1;
  border-radius: 32px;
  transition: var(--transition);
  cursor: pointer;
  border: 1px solid var(--borders);
}
.close:hover {
  border-color: var(--grey700);
}

.actionButtons {
  display: flex;
  flex-direction: row !important;
  gap: var(--padding-xxs);
  justify-content: flex-end;
  align-items: center;
}
.itemActions {
  display: flex;
  height: 27px;
  width: 27px;
  align-items: center;
  justify-content: center;
}
.itemActions:hover {
  background-color: var(--grey100);
  
}

.languageTabs {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid var(--borders);
}
.languageTab {
  font-size: 14px;
  font-weight: 500;
  padding: 12px var(--padding-xs);
  text-transform: capitalize;
  transition: var(--transition);
  color: var(--grey500);
}
.languageTab:hover {
  box-shadow: 0 -2px 0px 0 var(--grey700) inset;
  color: var(--grey700);
}
.languageTab.active {
  color: var(--brand);
  box-shadow: 0 -2px 0px 0 var(--brand) inset;
}

/* Admin Page */
.adminPage {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
}


.edit-image-dropzone {
  position: relative;
}
.edit-image-dropzone .upload-wrap {
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  gap: 8px;
  text-align: center;
  color: white;
  padding: 48px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.edit-image-dropzone .upload-wrap p {
  color: white;
}
.edit-image-dropzone .dish-img {
  width: 100%;
  height: 0;
  padding-top: 60%;
  border-radius: 0;
}

.edit-image-dropzone .dish-img p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.edit-image-dropzone:hover .upload-wrap {
  opacity: 1;
  visibility: visible;
}
.edit-image-dropzone:hover .dish-img p {
  opacity: 0;
  visibility: hidden;
}

.edit-image-dropzone .upload-wrap span {
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}
.edit-image-dropzone .upload-wrap span:before,
.edit-image-dropzone .upload-wrap span:after {
  content: "";
  height: 1px;
  width: 35%;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: white;
}
.edit-image-dropzone .upload-wrap span:after {
  left: auto;
  right: 0;
}
.edit-image-dropzone .upload-wrap button.delete {
  color: white;
  background-color: rgba(0, 0, 0, 0.23);
}

.modal-content .buttons button {
  flex: 1;
}



@media (max-width:960px) {

  .columns {
    flex-direction: column !important;
  }
  .menuAdmin .columns {
    gap: var(--padding-lg);
    flex-direction: column !important;
  }
  .column {
    width: 100% !important;
    border-radius: 0;
    max-width: 100vw;
    padding: 0 !important;
  }
}


.custom-select {
  position: relative;
  flex: 1;
}
.custom-select .icon {
  position: absolute;
  right: var(--padding-xs);
  z-index: 1;
}

.selected-option {
  background-color: white !important;
  text-transform: capitalize;
  font-size: 15px;
  color: var(--sm-text-color);
}


.options-list {
  position: absolute;
  box-shadow: 0 0 0 1px var(--borders);
  background: white;
  padding: 0;
  margin: 0;
  left: -1px;
  top: calc(100% + var(--padding-xxs));
  right: -1px;
  border-radius: 4px;
  z-index: 99;
}

.option {
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
}

.option:hover {
  background-color: var(--brand50)
}
.option.active,
.option.selected {
  background-color: var(--brand100);
  font-weight: 500;
  color: var(--brand600);
}

.submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  margin-top: 6px;
  margin-inline-start: 0 !important;
  background-color: white;
  right: 0;
  padding: 0;
  border-radius: var(--radius-xs);
  background-color: white;
  box-shadow: var(--box-shadow);
  z-index: 10;
  min-width: 180px;
  overflow: hidden;
  flex-direction: column;
}
.restaurantOptions {
  flex-direction: column;
}
.restaurantSelect .submenu {
  left: 0;
  right: auto;
}

.submenu ul,
.submenu .submenuInner {
  margin: 0;
  padding: 0;
  border-radius: var(--radius-sm);
  overflow: hidden;
}
.submenu li {
  display: flex;
  flex-direction: row;
}


.submenu li,
.submenu .submenuLink,
.submenu .closeSubmenu,
button.addRestaurant {
  padding: var(--padding-xs);
  color: var(--grey700);
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  flex-direction: row !important;
  flex: 1;
  align-items: center;
  gap: 8px;
  border-radius: 0;
  line-height: 1.5;
  white-space: nowrap;
}
button.addRestaurant {
  color: white;
}
.submenu li a,
.submenu .submenuLink {
  color: var(--grey700);
}

.submenu li.logout {
  font-weight: 400;
}

button.addRestaurant {
  border-bottom: 0;
  display: flex;
}
.submenu li:hover,
.submenu .submenuLink:hover,
.submenu .closeSubmenu:hover {
  background-color: var(--brand50);
}
.submenu li .icon path {
  fill: var(--grey400);
}

.submenu .closeSubmenu {
  display: none;
  border-radius: var(--radius-sm);
  font-weight: 600;
  border-bottom: 0;
}
.separator {
  height: 1px;
  background-color: var(--borders);
  width: 100%;
}

@media (max-width:960px) {
  .submenu {
    position: fixed;
    z-index: 999;
    top: auto !important;
    bottom: 8px !important;
    left: 8px !important;
    right: 8px !important;
    border-radius: var(--radius-sm);
    box-shadow: none;
    border-top: 1px solid var(--borders);
    margin: 0;
    background-color: transparent;
    gap: 8px;
  }
  .restaurant-profile-container .submenu {
    left: 8px;
    right: 8px;
  }
  .submenu li,
  .submenu .submenuLink {
    border-bottom: 1px solid var(--borders);
    background-color: white;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .submenu ul li:last-child {
    border-bottom: none;
  }
  .submenu li,
  .submenu .closeSubmenu {
    background-color: var(--grey100);
    justify-content: center;
    font-size: 16px;
  }
  button.addRestaurant {
    font-size: 16px;
    flex: 1;
    border-radius: var(--radius-sm);
  }
  .submenu .closeSubmenu {
    display: flex;
    background-color: white;
  }
  .separator {
    display: none;
  }
}

/* Style pour le Modal */
.import-modal {
  background: white;
  padding: var(--padding-md);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  max-width: 420px;
  margin: 40px auto;
  gap: var(--padding-sm);
  display: flex;
  flex-direction: column;
}
/* Style pour le Dropzone */
.dropzone {
  border: 2px dashed var(--brand);
  border-radius: var(--radius-md);
  padding: var(--padding-md);
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: border .3s ease-in-out;
}

.dropzone:hover {
  border-color: var(--brand600);
}

/* Style pour les textes à l'intérieur du Dropzone */
.dropzone p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Message de chargement et d'erreur/succès */
.message {
  padding: 10px 0;
  font-size: 14px;
}


.popupMessage {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  border: 1px solid;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: 500;
}

.color-setting {
  margin-bottom: 20px;
}

.color-input {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  width: 120px;
}

.color-preview {
  width: 32px;
  min-width: 32px;
  border-radius: 8px;
  height: 32px;
  border: 1px solid var(--grey700);
  margin-right: 10px;
}

.toggle-buttons  {
  gap: 0;
  border-radius: 6px;
  overflow: hidden;
  flex-direction: row !important;
}
.toggle-buttons button {
  flex: 1;
  border-radius: 0;
  background-color: #F1F1F1;
  color: var(--grey500);
  border: 2px solid transparent;
}
.toggle-buttons button.active {
  background-color: var(--brand);
  color: var(--grey700);
}
.productTypeTabContent {
  padding: 24px;
  gap: var(--padding-sm);
  background-color:  white;
  margin-top: -8px;
}
.variation {
  gap: var(--padding-sm);
  
}

.productTypeTabContent > .variaton:not(:first-child) {
  margin-top: var(--padding-xxs);
  padding-top: var(--padding-xxs);
  border-top: 1px solid var(--borders);
}

.variation h6 {
  flex-direction: row !important;
  flex: 1;
  justify-content: space-between;
}

.variation .variation-inputs,
.price-row {
  flex-direction: row !important;
  gap: var(--padding-xs);
  align-items: center;
}
.delete-variation {
  height: 24px;
  min-width: 24px !important;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  line-height: 1;
}
.field.price {
  width: 100px;
}
.field.ratio {
  width: 40px;
}
.field.ratio input {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
}




.allergenes {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 6px;
}
.allergene input {
  display: none !important;
}

.allergene input+label,
.customSelect-container .customSelect__multi-value {
  display: flex;
  background-color: white;
  border: 1px solid var(--borders);
  flex-direction: row !important;
  padding: 4px 12px 4px 6px;
  font-size: 13px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  margin: 0;
  width: fit-content;
}
.customSelect-container:not(#emailSelect) .customSelect__multi-value {
  text-transform: capitalize;
}

.allergene input+label .icon {
  width: 20px;
}
.allergene input+label:hover {
  background-color: var(--brand50);
}
.allergene input:checked+label {
  background-color: var(--brand);
  border-color: var(--brand);
  color: white;
}



.menuAdminBar {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid var(--borders);
  position: sticky;
  top: 0;
  min-height: 80px;
}
.menuAdminBar .container {
  flex-direction: row !important;
  align-items: center;
}


@media (max-width:960px){
  .menuAdminBar {
    flex-direction: column;
    gap: var(--padding-xs);
  }
}
.menu-switcher  {
  overflow-y: visible;
  width: 100%;
}
.menu-switcher::-webkit-scrollbar {
  display: none;
}

.menu-link {
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    gap: 8px;
    color: var(--grey600);
    text-transform: capitalize;
    transition: all .3s ease;
    cursor: pointer;
    line-height: 1;
    padding: var(--padding-xs) var(--padding-sm);
    transition: all .2s ease;
}
.menu-link:hover {
  box-shadow: 0 -2px 0px 0 var(--grey700) inset;
  color: var(--grey700);
}
.menu-link.active {
  color: var(--grey700);
  box-shadow: 0 -2px 0px 0 var(--brand) inset;
}
.menu-link .mainCat-icon {
  font-size: 24px;
}

/* settings */
.tabLinks {
  width: fit-content;
  gap: 16px;
  border: 0 !important;
  
}
.tabLinks .tabLink {
  cursor: pointer;
  justify-content: flex-end;
  color: var(--grey500);
  font-weight: 500;
  padding: 8px 0;
  gap: 8px;
  border-radius: 0;
}

.tabLinks .tabLink:not(.active):hover {
  color: var(--grey700);
}

.tabLinks .tabLink.active,
.tabLinks .tabLink:focus {
  color: var(--brand) !important;
}

.tabLinks .tabLink.active {
  font-weight: 500;
  box-shadow: 0 -2px 0 0 inset var(--brand);
}
.tab-content {
  padding: var(--padding-lg) 0 var(--padding-xl);
}

.settings-side-menu {
  gap: var(--padding-xxs);
}
.settings-side-menu a {
  color: var(--grey700);
  text-decoration: none;
}
.settings {
  gap: var(--padding-xl);
}
.settingsSection h4 {
  margin-bottom: var(--padding-sm);
}
.logoSettings {
  flex-direction: column !important;
  gap: var(--padding-md);
}
.logoPreview {
  width: 160px;
  border-radius: 160px;
  padding: var(--padding-sm);
  height: 160px;
  justify-content: center;
}
.colorsSettings,
.photoSettings {
  flex-direction: row !important;
  justify-content: space-between;
}




/* Spinner */

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.975);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  gap:16px
}

.loadingSpinner {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid var(--brand); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* TOGGLES */

.category-toggle {
  width: 100%;
  gap: var(--padding-xs);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--brand);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--brand);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Restaurant selection EditUSerModal */

.options-list.restaurants {
  top: 100%;
}

.restaurantDropdownInput {
  min-height: 56px;
}


.selected-tags {
  display: flex;
  flex-direction: row !important;
  gap: var(--padding-xxs);
  position: absolute;
  top: 11px;
  left: 16px;
  right: 16px;
  flex-wrap: wrap;
}

.selected-tags .tag {
  font-size: 15px;
  border-radius: var(--radius-lg);
  padding: 6px 12px 6px 18px;
  border: none;
  background-color: var(--brand100);
  gap: 8px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  color: var(--brand600);
}
.selected-tags .tag span {
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.selected-tags .tag span svg path {
  fill: var(--brand600);
}
.selected-tags .tag span:hover svg path {
  fill: var(--grey700);
}

.tagsField * {
  flex-direction: row !important;
}
.customSelect__multi-value__label {
  flex-direction: row !important;
}


.customSelect-container {
  width: 100%;
}
.customSelect-container .customSelect__control,
.customSelect-container .customSelect__control .customSelect__value-container {
  flex: 1;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  flex-direction: row !important;
}
.customSelect-container .customSelect__control {
  padding: 4px 8px;
  box-shadow: 0 0 0 1.5px var(--borders) !important;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
}
.customSelect__indicators {
  flex-direction: row !important;
}
.customSelect__value-container {
  gap: 4px;
  flex-direction: row !important;
}

.customSelect-container .customSelect__multi-value__label {
  font-weight: 500;
  font-size: 13px;
  color: var(--grey700);
  padding: 0;
  align-items: center;
  gap: 4px;

}
.customSelect__multi-value__remove {
  padding: 0 !important;
}

.customSelect__option--is-selected {
  background-color: var(--brand) !important;
}
.customSelect__option:hover {
  background-color: var(--brand50) !important;
}
.tagsField .customSelect__option {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  flex-direction: row !important;
  gap: 4px;
  align-items: center;

}
.tagsField .customSelect__option:hover,
.tagsField .customSelect__option:focus {
  background-color: var(--brand50);
}
.customSelect__menu * {
  text-transform: capitalize;
  width: 100%;
}
.customSelect__menu {
  width: fit-content !important;
}
.tagItems {
  gap: var(--padding-xxs);
}
.tagItem {
  gap: var(--padding-sm);
  padding: 12px 16px;
  font-size: 15px;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
}
.tagBackground {
  position: absolute;
  opacity: .075;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tagName {
  flex-direction: row !important;
  gap: var(--padding-xxs);
  align-items: center;
}
.tagPoint {
  height: 8px;
  width: 8px;
  border-radius: 12px;
}
.tagItem-actions {
  gap: 4px;
  font-size: 13px;
}
.tagItem-actions svg path {
  fill: var(--grey500);
}
.createTagSection {
  flex-direction: row !important;
  width: 100%;
}
.tagCreate {
  font-size: 13px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.emojiPicker {
  position: relative;
}
.delete-icon-btn {
  margin-left: 16px;
  font-size: 13px;
  border: 1px solid var(--borders);
  cursor: pointer;
  border-radius: var(--radius-xs);
  padding: var(--padding-xxs);
}
.epr_-kg0voo.epr_-lzq3oa {
  display: none;
}
.statusSelector__input {
  box-shadow: none !important;
}



/* ORDERS */

.orderWrapper {
  width: 100%;
  padding: var(--padding-lg) 0;
}
.orderWrapper .container {
  gap: var(--padding-md)
}
.orderWrapper table {
  width: 100%;
  font-size: 14px;
  color: var(--grey700);
}
table thead th {
  color: var(--grey400) !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
  letter-spacing: -0.02em !important;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

table th,
table td
 {
  text-align: left;
  border-bottom: 1px solid var(--borders);
}

.orderDate {
  font-weight: 500;
  font-size: 13px;
  color: var(--grey500);
}
.detailsTable td {
  padding: var(--chakra-space-2) 0;
}

.orderDetails .itemName,
.orderDetails .extrasList {
  margin-bottom: 8px;
}
.statusSelectorContainer {
  width: fit-content !important;
}

.statusSelectorContainer .statusSelector__control {
  background-color: transparent !important;
  border: none !important;
}
.statusSelectorContainer .statusSelector__control--is-focused {
  box-shadow: none !important;
}
.statusSelectorContainer .statusSelector__indicator,
.statusSelectorContainer .statusSelector__indicator-separator {
  display: none !important;
}
.statusSelectorContainer .statusSelector__value-container,
.statusSelectorContainer .statusSelector__input-container,
.statusSelectorContainer .statusSelector__single-value,
.statusSelectorContainer .statusSelector__indicator {
  padding: 0;
  margin: 0;
}

.statusSelectorContainer .statusSelector__menu {
  width: fit-content;
}
.statusSelectorContainer .statusSelector__option:hover {
  background-color: var(--brand50);
}
.statusSelectorContainer .statusSelector__option--is-selected {
  background-color: var(--grey100) !important;
  color: var(--grey700);
}

.order:hover {
  background-color: var(--grey25);
}

@media (max-width: 960px) {
  .orderWrapper thead {
    display: none;
  }
  .order {
    display: block;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--borders);
  }
 
  .order td {
    border: none;
    padding: 0;
  }

  /* Ensures the first <td> takes an entire row */
  .order > td:first-child {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }

  /* Places the rest of the <td> elements on the second row */
  .order > td:not(:first-child) {
    display: inline-block;
    width: fit-content;
    margin-right: 8px;
  }
  .order > td:not(:first-child):last-child {
    margin-right: 0;
  }
  .orderHeader th {
    display: none; /* Hide the headers on mobile if not necessary */
  }
}




.ReactModal__Overlay .ReactModal__Content {
  opacity: 0;
  transition: all 250ms ease-in-out;
  transform: translateY(100px);
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  opacity: 0;
  transform: translateY(30px);
}


/* Analytics */
section {
  display: flex;
  flex-direction: column;
}
.sectionHeader {
  flex-direction: row;
  justify-content: space-between;
}
.dateRangePicker {
  position: absolute;
  top:  calc(100% + 8px);
  z-index: 1;
  right: 0;
  border: 1px solid var(--borders);
  border-radius: 8px;
  overflow: hidden;
}

.summary {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
  background-color: white;
  margin-left: 0;
}
.summary li {
  display: flex;
  flex: 1;
  gap: 8px;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--borders);
  line-height: 1.4em;
  text-decoration: none;
  color: #1e1e1e;
  padding-left: 24px;

}
.summary li:first-child {
  padding-left: 0;
}
.summary li:last-child {
  border-right: 0;
}
.kpi {
  font-size: 20px;
  font-weight: 700;
  color: var(--grey700);
}
.kpi span {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey500);
}

@media (max-width: 960px) {
  .summary {
    flex-direction: column;
  }
  .summary li {
    flex-direction: row;
    justify-content: space-between;
    border-right: 0;
    border-bottom: 1px solid var(--borders);
    padding: 12px 0px;
  }
  .summary li:last-child {
    border-bottom: 0;
  }
}


.chakra-checkbox__label,
.chakra-radio__label {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  font-weight: 400;
}
.chakra-tabs__tablist {
  background-color: white;
  border-bottom: 1px solid var(--borders);
}
.chakra-tabs__tab {
  color: var(--grey700);
  font-weight: 500;
  color: var(--grey500);
  font-size: 14px;
}
.chakra-tabs__tab:hover {
  color: var(--grey700);
}
.chakra-tabs__tab[aria-selected=true] {
  color: var(--brand);
}


/* Pagination */

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;
}
.resultsNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.pages {
  color: var(--grey500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.pages button {
  display: flex;
  padding: 0 !important;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey300);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.pages button:disabled {
  border-color: var(--grey100);
  color: var(--grey300);
}
.pages button svg {
  height: 20px;
  width: 20px;
}
.pages button svg path {
  stroke: var(--grey700)
}
.pages button:disabled svg path {
  stroke: var(--grey300)
}
.pages > span {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey700);
}
.pages > span > span {
  font-weight: 500;
}

.timeSelect select {
  width: fit-content;
  font-size: 13px;
  padding: 4px 0 4px 8px;
  height: auto !important;
  border: none !important;
}

.ingSelectContainer .guddenSelect__control {
  border: none;
}

.guddenSelect__input,
.guddenSelect__control.guddenSelect__control--is-focused {
  box-shadow: none !important;
  
}
.guddenSelect__control.guddenSelect__control--is-focused {
  border-color: var(--brand) !important;
}
.ingSelectContainer .guddenSelect__value-container {
  padding: 0 !important;
}

.guddenSelect__option--is-selected {
  background-color: var(--brand) !important;
  color: white !important;
}
.guddenSelect__option--is-focused {
  background-color: var(--brand100) !important;
  color: black !important;
}
.guddenSelect__indicator-separator {
  display: none !important;
}

.stockTable th,
.stockTable tr td {
  padding: 8px;
}

.chakra-tabs__tab-panels > div {
  padding: 0;
  width: 100%;
}

.languageButtons button.active {
  box-shadow: 0 0 0 2px var(--brand);
  opacity: 1;
}


.history-log .log .logIcon svg {
  background-color: white;
  box-shadow: 0 0 0 4px white;
}

.history-log .log .logIcon:before {
  content: '';
  width: 3px;
  background-color: var(--grey150);
  display: block;
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.deliverer .customSelect__control {
  min-height: 0;
  padding: 0;
  box-shadow: none !important;
  background: none;
}
.deliverer .customSelect__indicator-separator {
  display: none;
}
.deliverer .customSelect__input-container,
.deliverer .customSelect__indicator {
  padding: 0;
  margin: 0;
}
.deliverer .customSelect__indicator svg {
  height: 15.5px;
  width: 15.5px;
}


/* Charts */
.recharts-text {
  fill: var(--grey400) !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.recharts-legend-item {
  font-size: 14px;
  font-weight: 500;
}
.recharts-legend-item-text {
  color: var(--grey600) !important;
}