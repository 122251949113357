@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  #edit-item-modal .modal-content-animating {
    animation: slideInFromRight 0.25s ease-out forwards;
  }

  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  #edit-item-modal .modal-content-exiting {
    animation: slideOutToRight 0.25s ease-out forwards;
  }
  
  